import { Box, Stack, Theme } from '@mui/material'

import { ActionButton } from './ActionButton'
import { HeroText, BlurbText } from './typography'
import blueishBanner from '../../../assets/hero-background.webp'
import { BreakpointDevice, useBreakpoint } from '../../../theme/useBreakpoint'

export function Hero() {
  const { device } = useBreakpoint()

  const bgImageStyle = {
    backgroundImage: `url(${blueishBanner})`,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
  }

  const mobileHeroContainerStyle = {
    ...bgImageStyle,

    mx: -2,
    px: 4,
    py: 2,
    mb: 4,
  }

  const desktopHeroContainerStyle = {
    ...bgImageStyle,
    borderRadius: 6,
    p: 10,
    mb: 4,
  }
  return (
    <Box
      sx={
        device === BreakpointDevice.Mobile
          ? mobileHeroContainerStyle
          : desktopHeroContainerStyle
      }
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        gap={3}
        sx={{ maxWidth: (theme) => theme.spacing(89) }}
      >
        <HeroText
          sx={{
            color: (theme: Theme) => theme.palette.primary.contrastText,
          }}
        >
          Act like the business, <br />
          Think like IT
        </HeroText>

        <BlurbText
          sx={{ color: (theme: any) => theme.palette.primary.contrastText }}
        >
          Whether you need help with data integration, warehousing analytics, or
          visualization, we have the expertise and resources to help you achieve
          your goals. Contact us today to learn more about how we can help you
          act like the business and think like IT!
        </BlurbText>

        <ActionButton gradient>Get in touch</ActionButton>
      </Stack>
    </Box>
  )
}
