import { MainPage } from './main-page'
import { ContactPage } from './contact-page'
import { ComingSoonPage } from './coming-soon-page'

export const paths = ['/', '/contact', '/coming-soon']

export type AppRoute = {
  path: string
  title: string
  example: React.FC
}

export const pages = [
  { path: paths[0], title: 'Home', example: MainPage },
  { path: paths[1], title: 'Contact', example: ContactPage },
  { path: paths[2], title: 'Coming Soon', example: ComingSoonPage },
]
