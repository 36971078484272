import { Service } from '../../features/site/components/Service'
import { Feature } from '../../types'

// TODO: Get the logo for the service

export function PowerBITipsPlusService() {
  return <Service title="tips+" features={powerBITipsPlusFeatures}></Service>
}
//TODO: Review description and add images

const powerBITipsPlusFeatures: Feature[] = [
  {
    title: 'Subscription',
    description:
      'The subscription service called Tips+, now lets users view their custom theme files in real time. Did all this work to develop a theme file and you want to keep your work, no problem. Users can easily save their developed files into our cloud service and recall them anytime they want. Finally, users enjoy an ad-free experience. Tips+ offers a convenient and efficient way to take full advantage of the Power BI platform and make the most of the customizations available.',
    link: 'https://powerbi.tips/product/tips-plus/',
    buttonText: 'Try it now',
  },
  {
    title: 'Themes',
    description:
      'The best theme generator just got better with Tips+. Users can save and edit their themes easily, ensuring a seamless editing process. Tips+ is an essential tool for anyone who wants to create personalized and professional reports in Power BI.',
    link: 'https://themes.powerbi.tips/themes/palette',
    buttonText: 'Try it now',
  },
]
