import { ReactNode } from 'react'
import { StyledHeaderText } from './Typography.styles'

type HeaderTextProps = {
  sx?: any
  children: ReactNode
  disableGradient?: boolean
}

export function HeaderText(props: HeaderTextProps) {
  const { sx, children, disableGradient } = props
  return (
    <StyledHeaderText
      sx={{
        ...sx,
        backgroundImage: disableGradient && 'none !important',
        WebkitTextFillColor: (theme) =>
          disableGradient && theme.palette.text.primary + ' !important',
      }}
    >
      {children}
    </StyledHeaderText>
  )
}
