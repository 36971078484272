import { Service } from '../../features/site/components/Service'
import { Feature } from '../../types'

export function IntelexosService() {
  return (
    <Service
      title="Intelexos"
      // description="Power BI embedded accelerator"
      features={intelexosFeatures}
    ></Service>
  )
}

const intelexosFeatures: Feature[] = [
  {
    title: 'PBI Embedded Accelerator',
    description:
      'Power BI embedding is not hard, but managing the data around a proper embed is challenging. This accelerator is a custom software as a service or enterprise deployment that enables your organzition to use the existing Power BI investment. We have developed over 10+ emebdded solutions around Power BI. Taking from all the knowledge we optimized this soution to manage uers, reports, row level security in an easy to use package. Build on all Azure and using a modern app dvelopment stack get a cost effective solution deliver in days not months.',
  },
]
