import { Box, Stack } from '@mui/material'
import { BreakpointDevice, useBreakpoint } from '../../../theme/useBreakpoint'
import { Feature } from '../../../types'

import { Blurb } from './Blurb'

// import bbburry from '../../../assets/bbblurry.svg'

type FeaturesProps = {
  features: Feature[]
}

export function Features(props: FeaturesProps) {
  const { features } = props
  const featuresWithDirection = features.map((feature, index) => ({
    ...feature,
    left: index % 2 === 0,
  }))

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: '100%',
        display: 'flex',
        marginTop: '40px',
      }}
      gap={12}
    >
      {featuresWithDirection.map((feature, index) => (
        <FeatureBox key={feature.title+index} feature={feature} />
      ))}
    </Stack>
  )
}

type FeatureProps = {
  feature: Feature
}

export function FeatureBox(props: FeatureProps) {
  const { feature } = props

  const { title, description, image, left, buttonText, link } = feature
  const { device } = useBreakpoint()

  const direction =
    device === BreakpointDevice.Mobile
      ? 'column-reverse'
      : left
      ? 'row'
      : 'row-reverse'

  return (
    <Stack
      direction={direction}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: '100%',
        px: device === BreakpointDevice.Mobile ? 2 : 10,
      }}
      gap={8}
    >
      <Blurb
        sx={{
          width: device === BreakpointDevice.Mobile ? '100%' : '50%',
          flexShrink: 0,
        }}
        title={title}
        description={description}
        buttonText={buttonText}
        link={link}
        externalLink={!!buttonText}
      />

      <Box
        sx={{
          width: '100%',
          maxWidth: '600px',
          // height: '300px',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {image && (
          <img
            alt="feature"
            src={image}
            style={{
              width: '100%',
              maxWidth: '480px',
              objectFit: 'cover',
            }}
          />
        )}
      </Box>
    </Stack>
  )
}

//function FeaturesRow(props: { features: Feature[] }) {
//   const numberOfFeaturesToDisplay = 3

//   const [startIndex, setStartIndex] = React.useState(0)

//   const leftButtonDisabled = startIndex === 0
//   const rightButtonDisabled =
//     startIndex === props.features.length - numberOfFeaturesToDisplay

//   return (
//     <Stack
//       direction="row"
//       gap={2}
//       sx={{
//         width: props.features.length > 2 ? '800px' : '600px',
//         height: '200px',
//         boxSizing: 'border-box',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}
//     >
//       {props.features.length > numberOfFeaturesToDisplay && (
//         <Button
//           onClick={() => {
//             if (startIndex > 0) setStartIndex(startIndex - 1)
//           }}
//           sx={{
//             fontSize: '24px',
//             '&:hover': {
//               backgroundColor: 'transparent',
//             },
//             color: (theme) => theme.palette.text.primary,
//           }}
//           disabled={leftButtonDisabled}
//         >
//           {'<'}
//         </Button>
//       )}

//       {props.features.map((feature, index) => {
//         if (
//           index >= startIndex &&
//           index < startIndex + numberOfFeaturesToDisplay
//         ) {
//           return (
//             <Stack
//               direction="column"
//               key={index}
//               sx={{
//                 width: '300px',
//                 height: '100%',
//                 paddingTop: '224px',
//                 boxSizing: 'border-box',
//                 display: 'flex',
//                 justifyContent: 'flex-start',
//                 alignItems: 'center',
//                 flexGrow: 1,
//                 textAlign: 'center',
//               }}
//             >
//               {feature.icon}

//               <Typography variant="h6" component="h2">
//                 {feature.title}
//               </Typography>

//               <Typography>{feature.description}</Typography>
//             </Stack>
//           )
//         }
//       })}

//       {props.features.length > numberOfFeaturesToDisplay && (
//         <Button
//           onClick={() => {
//             if (startIndex < props.features.length - numberOfFeaturesToDisplay)
//               setStartIndex(startIndex + 1)
//           }}
//           disabled={rightButtonDisabled}
//           sx={{
//             fontSize: '24px',
//             '&:hover': {
//               backgroundColor: 'transparent',
//             },
//             color: (theme) => theme.palette.text.primary,
//           }}
//         >
//           {'>'}
//         </Button>
//       )}
//     </Stack>
