import { Stack } from '@mui/material'
import { ActionButton } from './ActionButton'
import { HeaderText } from './typography/HeaderText'
import { BlurbText } from './typography'

type BlurbProps = {
  title: string
  description: string
  buttonText?: string
  link?: string
  externalLink?: boolean
  sx?: any
}

export function Blurb(props: BlurbProps) {
  const { title, description, buttonText, link, externalLink, sx } = props

  return (
    <Stack direction="column" spacing={2} alignItems="flex-start" sx={sx}>
      <HeaderText disableGradient={externalLink}>{title}</HeaderText>
      <BlurbText>{description}</BlurbText>

      <ActionButton link={link} externalLink={externalLink}>
        {buttonText || 'Contact'}
      </ActionButton>
    </Stack>
  )
}
