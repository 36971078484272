import { Box } from '@mui/material'

import logo from '../../assets/powerbitips.svg'

import ViewComfyIcon from '@mui/icons-material/ViewComfy'
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'

import pbiTipsBackground from '../../assets/pbitips-background.webp'
import { Features } from '../../features/site/components'
import { Feature, HighlightedFeature } from '../../types'

import pbiThemeColorPicker from '../../assets/pbi-theme-color-picker.webp'
// import themedPBIReport from '../../assets/themed-pbi-report.webp'
import businessOps from '../../assets/business-ops.webp'
import { Service } from '../../features/site/components/Service'
import { HighlightedFeatures } from '../../features/site/components/HighlightedFeatures'

// TODO: update the image to be less blurry

export function PowerBITipsService() {
  return (
    <Box>
      <Service
        title="PowerBI.tips"
        description="The PowerBI.tips blog has been around since the beginning. Visit our trused blog for the latest tips, tricks for Power BI. Our expert insights and real-world examples will help you get the most out of Power BI and take your data analysis to the next level. "
        logo={
          <img src={logo} alt="Power BI tips logo" style={{ width: '100%' }} />
        }
        backgroundImage={pbiTipsBackground}
        contrast={true}
      >
        <HighlightedFeatures
          features={highlightedFeatures}
          heading="Need help creating beautiful reports?"
        />
      </Service>
      <Features features={features} />
    </Box>
  )
}

const features: Feature[] = [
  {
    title: 'Business Ops',
    description:
      'Business Ops is a free tool designed to simplify the process of finding, downloading, and installing external tools for Power BI. With Business Ops, users no longer need to spend time searching for and installing each tool individually. Instead, they can browse through a library of available tools and easily download and install them with just a few clicks. This tool also provides access to many of our other Power BI tools, including our Power BI Theme Generator and Dax.do.',
    link: 'https://powerbi.tips/product/business-ops/',
    image: businessOps,
    buttonText: 'Try it now',
  },
  {
    title: 'Custom themes',
    description:
      'Our Power BI Theme Generator is a tool that helps users create custom themes and visuals for their reports, using a user-friendly interface with a variety of options for colors, fonts, and styles. It allows for the creation of professional-looking reports with ease, with numerous options for customization.',
    image: pbiThemeColorPicker,
    link: 'https://themes.powerbi.tips/themes/palette/',
    buttonText: 'Try it now',
  },
]

const highlightedFeatures: HighlightedFeature[] = [
  {
    title: 'Free Tooling',
    description:
      'We are community driven, offering tools like theme generator, color gallery, collections code on github. We aim to design impactful tools for everyone to use and work smarter not harder.',
    icon: <ConstructionOutlinedIcon fontSize="large" />,
    link: 'https://powerbi.tips/tools/',
  },

  {
    title: 'Layouts',
    description:
      'We created Layouts before it was a thing. Download our layouts which cuts your report development time down. Downlaod a free layout to see how it can make you faster at build an awesome looking report. ',
    icon: <ViewComfyIcon fontSize="large" />,
    link: 'https://powerbi.tips/tools/layouts/',
  },
  {
    title: 'Scrims',
    description:
      'A scrim a stylized image to aid in reporting design. It uses a pleasing color pallete and draws from Gestalt Design Principals for value added reporting. ',
    icon: <PhotoOutlinedIcon fontSize="large" />,
    link: 'https://powerbi.tips/2019/12/introducing-scrims/',
  },
  // {
  //   title: 'Blog',
  //   description: 'Blog about Power BI',
  //   icon: <LanguageIcon fontSize="large" />,
  // },
  // {
  //   title: 'jams.powerbi.tips',
  //   description: 'Jam sessions for Power BI',
  //   icon: <EqualizerOutlinedIcon fontSize="large" />,
  // },
]
