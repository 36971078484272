import ReactGA from 'react-ga4'
import {
  EventCategory,
  EventOptions,
  PageViewOptions,
  PbtGoogleAnalyticsConfig,
} from './google-analytics.models'

class PbtGoogleAnalytics {
  private static classInstance: PbtGoogleAnalytics
  private testMode?: boolean

  public isInitialized(): boolean {
    return ReactGA.isInitialized
  }

  public initialize(config: PbtGoogleAnalyticsConfig): void {
    
    ReactGA.initialize(
      [
        {
          trackingId: config.trackingId,
          gaOptions: {
            send_page_view: false,
          },
          gtagOptions: {
            // Enable this for testing in local environment
            // debug_mode: true,
            send_page_view: false,
          },
        },
      ],
      { testMode: config.testMode }
    )
    this.testMode = config.testMode
  }

  public accountsEvent(options: EventOptions, params?: any) {
    this.event(
      {
        action: options.action,
        label: options.label,
        value: options.value,
      },
      EventCategory.Accounts,
      params
    )
  }

  public pageView(options: PageViewOptions): void {
    const eventOptions = {
      hitType: 'pageview',
      page: options.page,
      title: options.title,
      location: options.location,
    }
    ReactGA.send(eventOptions)

    if (this.testMode) {
      console.info('[pbt-ga]', `\n${JSON.stringify(eventOptions)}`)
    }
  }

  private event(options: EventOptions, category: string, params?: any) {
    const eventOptions = { ...options, category }
    ReactGA.event(eventOptions, params)

    if (this.testMode) {
      console.info('[pbt-ga]', `\n${JSON.stringify(eventOptions)}`)
    }
  }

  static getInstance(): PbtGoogleAnalytics {
    if (!this.classInstance) {
      this.classInstance = new PbtGoogleAnalytics()
    }
    return this.classInstance
  }
}

export default PbtGoogleAnalytics
