import { Route, Routes } from 'react-router-dom'

import { pages } from './pages'
import { Box } from '@mui/material'
import { usePageViewTracking } from './utilities/google-analytics'

export function CarloRoutes() {
  usePageViewTracking()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {/* <TabBar /> */}
      <Routes>
        {pages.map((page) => (
          <Route key={page.path} path={page.path} element={<page.example />} />
        ))}
      </Routes>
    </Box>
  )
}
