import { Box } from '@mui/material'
import { Header } from './Header'

type PageProps = {
  children: React.ReactNode
  backButton?: boolean
}
//container --> can include any child components
export function Page(props: PageProps) {
  const { children, backButton } = props

  return (
    <Box
      sx={{
        width: '100%',
        minWidth: '300px',
      }}
    >
      <Header maxWidth="1800px" backButton={backButton} />
      <Box sx={{ maxWidth: '1800px', margin: '0 auto', px: 2 }}>{children}</Box>
    </Box>
  )
}
