import { useState } from 'react'
import PbtGoogleAnalytics from './google-analytics'

export const useGoogleAnalytics = (): PbtGoogleAnalytics => {
  const initializeAnalytics = (): PbtGoogleAnalytics => {
    const trackingId = process.env.REACT_APP_GA_KEY
    const environment = process.env.REACT_APP_ENV_NAME
    const analytics = PbtGoogleAnalytics.getInstance()

    if (trackingId && !analytics.isInitialized()) {
      analytics.initialize({
        trackingId,
        testMode: environment !== 'prod',
      })
    }

    return analytics
  }

  const [ga] = useState<PbtGoogleAnalytics>(initializeAnalytics())
  return ga
}
