// import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar'
// import SsidChartIcon from '@mui/icons-material/SsidChart'
// import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined'
// import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
// import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined'
// import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'

import { Feature, HighlightedFeature } from '../../types'

import blueSmoke from '../../assets/blue-smoke.webp'
import { Features } from '../../features/site/components'
import { Box } from '@mui/material'
import { ReactComponent as CarloSolutionsLogo } from '../../assets/carlo-solutions-logo-white.svg'

import ComputerIcon from '@mui/icons-material/Computer'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone'
import { HighlightedFeatures } from '../../features/site/components/HighlightedFeatures'
import { Service } from '../../features/site/components/Service'

export function CarloSolutionsService() {
  return (
    <Box>
      <Service
        title="Carlo Solutions"
        // description="Longer description of the service"
        backgroundImage={blueSmoke}
        logo={<CarloSolutionsLogo style={{ width: '100%' }} />}
        contrast={true}
      >
        <HighlightedFeatures
          features={highlightedFeatures}
          heading="Need help managing your data?"
        ></HighlightedFeatures>
      </Service>
      <Features features={carloSolutionsFeatures}></Features>
    </Box>
  )
}

const highlightedFeatures: HighlightedFeature[] = [
  {
    title: 'Consulting Services',
    description:
      'Expert guidance and support to help businesses optimize their data analytics capabilities and leverage the full potential of Power BI for informed decision-making.',
    icon: <GroupOutlinedIcon fontSize="large" />,
  },
  {
    title: 'Accelerators',
    description:
      'Modern Data Warehouse, Power BI Center of Excellence, Power BI Monitoring, and Embedding solutions using Power BI.',
    icon: <InsertChartOutlinedTwoToneIcon fontSize="large" />,
  },
  {
    title: 'App Development',
    description:
      'High-quality and customizable applications from our team of full-stack developers.',
    icon: <ComputerIcon fontSize="large" />,
  },
]

const carloSolutionsFeatures: Feature[] = [
  {
    title: 'Modern Data Warehouse Accelerator',
    description:
      "Our modern data warehouse accelerator is an innovative solution designed to help businesses efficiently store, manage, and analyze large volumes of data. With advanced features like cloud-based storage, automated data processing, and real-time analytics, our accelerator provides businesses with the tools they need to make informed decisions and gain a competitive edge. Whether you're a small business or a large enterprise, our modern data warehouse accelerator can help you streamline your data management process and achieve your business goals.",
    // iconOrImage: <InsertChartOutlinedOutlinedIcon fontSize="large" />,
  },
  {
    title: 'Center of Excellence (CoE) Accelerator',
    description:
      "Our team members co-lead your Center of Excellence (CoE) developing a winning strategy and positive habits. The CoE must be expected to deliver real business value back to the organization. Thus, working with individuals who have already done this leads by example. Community is a challenge to build,  planning is always required, we've already delveoped a world class community at PowerBI.tips and want to help your team also.",
  },
  {
    title: 'PBI Monitor Accelerator',
    description:
      "If you don't measure it you can't change the outcome. Power BI is no exception. Administrators need the ability to see what activity is occuring within the Power BI tenant. We build an out of the box solution that provides insights immediately. Learn who is the most active user in your tenant. Which datasets are failing refresh. Who has access to certified reports. All these questions and more are answerd when you have a robust monitoring solution for Power BI. This solution is extendible and grows with your company's needs. Therefore our accelerator is just the start of your world class Power Bi monitoring solution.",
    // iconOrImage: <SsidChartIcon fontSize="large" />,
  },
  {
    title: 'Consulting Services',
    description:
      'Our consulting services provide expert guidance to help your business grow and succeed. Need help with App Development, Power BI or Data Engineering, we can help you build and optimize your solutions.',
  },
  {
    title: 'App Development',
    description:
      'We know a thing or two about app development. Developing modern solutions with the Azure development stack we can build killer solutions. Need help with payment systems, user identity management or continuous integration and deployment implementations you have found the right team!',
  },
  {
    title: 'DataOps Accelerator',
    description:
      'DataOps (Data Operations) is an agile and collaborative approach to managing data throughout its lifecycle, from ingestion to consumption. It involves automating and streamlining data integration, testing, deployment, and monitoring processes, while also ensuring data quality, security, and compliance. Now think about how you would implement DataOps with Power BI. Bingo! Bring the same rigor you use for code mangement to data managment. Build tests, verify that data model changes are not implacting losses in data integrity. This solutions builds upon Dev Ops practices for data.',
  },
]
