// import logo from '../../assets/powerbitips-black.svg'

// import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
// import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import { Service } from '../../features/site/components/Service'
import { Feature } from '../../types'

export function CarloTrainingService() {
  return (
    <Service
      title="C+C | Carlo + Coates Training"
      description="Effectively Govern Power BI"
      // logo={<img src={logo} alt="Power BI Tips" style={{ width: '100%' }} />}
      features={carloTrainingFeatures}
    ></Service>
  )
}

const carloTrainingFeatures: Feature[] = [
  {
    title: 'Power BI Deployment & Governance',
    description:
      'The comprehensive Power BI Deployment & Governance online course will help you focus, save you time, and reduce your effort. You will be able to get your questions answered, and talk through ideas and challenges related to your Power BI tenant. Learn from Mike Carlo and Melissa Coates course trainers. This course mixes online self paced training with monthly workshops and live Q&A discussions.',
  },
  {
    title: 'Persona based training',
    description:
      'Think about the various activities users perform while working with Power BI; report consumers, report builders, data modelers and Admins. We provide training to address knowledge across these areas. Power BI changes quickly and you need a training that teaches best practices. Contact us about our training opportunites for your team.',
  },
]
