import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { BrowserRouter as Router } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { theme } from './theme/theme'
import { CarloRoutes } from './routes'

function App(): JSX.Element {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <Router>
            <CssBaseline />
            <CarloRoutes />
          </Router>
        </RecoilRoot>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
