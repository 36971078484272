import { useEffect, useState } from 'react'
import { Box, alpha, Stack } from '@mui/material'

import { ReactComponent as Logo } from '../../../assets/carlo-solutions-logo.svg'
import { ActionButton } from './ActionButton'
import { BreakpointDevice, useBreakpoint } from '../../../theme/useBreakpoint'

type HeaderProps = {
  maxWidth: string
  backButton?: boolean
}

export function Header(props: HeaderProps) {
  const { maxWidth, backButton } = props
  const [scrolled, setScrolled] = useState(false)

  const { device } = useBreakpoint()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setScrolled(window.pageYOffset > 400)
      )
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', () =>
          setScrolled(window.pageYOffset > 400)
        )
      }
    }
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '80px',
        position: 'sticky',
        top: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 2,
        mb: 2,
        //blur background
        backdropFilter: 'blur(10px)',
        //show shadow if scroll

        boxShadow: (theme) =>
          scrolled
            ? `0px 2px 4px -1px ${alpha(theme.palette.common.black, 0.2)},
        0px 4px 5px 0px ${alpha(theme.palette.common.black, 0.14)},
        0px 1px 10px 0px ${alpha(theme.palette.common.black, 0.12)}`
            : 'none',

        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        zIndex: (theme) => theme.zIndex.appBar + 1,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          maxWidth: (theme) => `calc(${maxWidth} - ${theme.spacing(4)})`,
          width: '100%',
          px: device === BreakpointDevice.Mobile ? 1 : 'unset',

          margin: '0 auto',
        }}
      >
        <Logo
          width={device === BreakpointDevice.Mobile ? '200px' : '280px'}
          height="60px"
        />

        {backButton ? (
          <ActionButton backArrow link="./">
            Back
          </ActionButton>
        ) : (
          <ActionButton>Contact</ActionButton>
        )}
      </Stack>
    </Box>
  )
}
