import { styled, Typography } from '@mui/material'

export const StyledHeroText = styled(Typography)(({ theme }) => ({
  // color: theme.palette.primary.contrastText,
  fontFamily: theme.custom.fonts.header.fontFamily,
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: '3.75rem',
    lineHeight: '180%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
}))

export const StyledBlurbText = styled(Typography)(({ theme }) => ({
  lineHeight: '180%',
  fontFamily: theme.custom.fonts.blurb.fontFamily,
  fontWeight: 500,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}))

export const StyledHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontFamily: theme.custom.fonts.header.fontFamily,
  fontWeight: 400,

  backgroundColor: 'primary',
  backgroundImage: `linear-gradient(45deg, #2bb7ef, #2f7b9c, #2f7b9c,#324e5e,#324e5e)`,
  backgroundSize: '100%',
  backgroundRepeat: 'repeat',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
    lineHeight: '180%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
}))

export const StyledFeatureText = styled(Typography)(({ theme }) => ({
  lineHeight: '180%',
  // color: theme.palette.primary.main,
  fontFamily: theme.custom.fonts.header.fontFamily,
  fontWeight: 400,
  textAlign: 'center',

  [theme.breakpoints.up('lg')]: {
    fontSize: '2.25rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.75rem',
  },
}))
