import { Breakpoint, createTheme } from '@mui/material'

const headerHeight: number = 0
const tabBarHeight: number = 0

const contentWidths: { [key in Breakpoint]: number } = {
  xs: 327,
  sm: 366,
  md: 381,
  lg: 720,
  xl: 1136,
}

interface Typography {
  fontFamily: string
  fontWeightLight: number
  fontWeightRegular: number
  fontWeightMedium: number
}

interface CustomThemeOptions {
  headerHeight: number
  tabBarHeight: number
  contentWidths: { [key in Breakpoint]: number }
  fonts: {
    header: Typography
    blurb: Typography
  }
}

export const themeColors = {
  darkText: '#2b2b2b',
  themeYellow: '#f1c80f',
  themeLightBlue: '#00aae1',
  themeDarkBlue: '#333f49',
  surface: '#efefef',
  lightGray: '#fafbfc',
  lightText: '#f5f5f5',
}

export const newThemeColors = {
  lightBlue: '#2bb7ef',
  blue: '#2f7b9c',
  darkBlue: '#324e5e',
  darkGreen: '#333f49',
  neonGreen: '#18ff6d',
  aquaGreen: '#4e937a',
  aquaGreenDark: '#416962',
  purple: '#524174',
  purpleOrange: '#ab4e6a',
  orange: '#ff5a5f',
  yellow: '#f1c80f',
  text: '#2e3942',
  darkText: '#0a0d0f',
}

declare module '@mui/material' {
  interface Theme {
    custom: CustomThemeOptions
  }
  interface ThemeOptions {
    custom: CustomThemeOptions
  }
}

// [TODO]: move this to another file later
export const theme = createTheme({
  custom: {
    headerHeight: headerHeight,
    tabBarHeight: tabBarHeight,
    contentWidths: contentWidths,
    fonts: {
      header: {
        fontFamily: ['Blinker', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 700,
      },
      blurb: {
        fontFamily: ['Lato', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
      },
    },
  },
  palette: {
    primary: {
      main: newThemeColors.blue,
      light: newThemeColors.lightBlue,
      dark: newThemeColors.darkBlue,
    },
    secondary: {
      main: newThemeColors.aquaGreen,
      light: newThemeColors.neonGreen,
      dark: newThemeColors.aquaGreenDark,
    },
    info: {
      main: newThemeColors.purple,
      light: newThemeColors.purpleOrange,
      dark: newThemeColors.orange,
    },

    background: {
      default: '#e4e4e4',
    },
    text: {
      primary: newThemeColors.text,
      secondary: newThemeColors.darkText,
    },
  },

  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    button: {
      textTransform: 'capitalize',
      fontSize: '1rem',
    },
  },
})
