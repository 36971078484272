import { ReactNode } from 'react'
import { StyledBlurbText } from './Typography.styles'

export function BlurbText(props: {
  sx?: any
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
  children: ReactNode
}) {
  const { sx, children, align } = props
  return (
    <StyledBlurbText sx={sx} align={align ? align : 'inherit'}>
      {children}
    </StyledBlurbText>
  )
}
