import { Box, Stack } from '@mui/material'
import { BreakpointDevice, useBreakpoint } from '../theme/useBreakpoint'
import { ContactHero } from '../features/site/components/ContentHeader'
import { Page } from '../features/site/components/Page'

export function ContactPage() {
  return (
    <Page backButton>
      <Content />
    </Page>
  )
}

function Content() {
  const { device } = useBreakpoint()
  const mobile = device === BreakpointDevice.Mobile

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      marginBottom="100px"
    >
      {!mobile && <ContactHero />}
      <ContactForm mobile={mobile} />
    </Box>
  )
}

type ContactFormProps = {
  mobile: boolean
}

function ContactForm(props: ContactFormProps) {
  const { mobile } = props

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',

        marginTop: mobile ? 0 : -45,
        borderRadius: '100px',
      }}
    >
      <iframe
        title="Contact Form"
        src="https://forms.office.com/Pages/ResponsePage.aspx?id=fFRm86p5WEq7H4U-6yELH0zWuHtCsuNNoI-sBWUJBnJUQ0szMjlDU0cyOFVPNTFDVzhXTVFBQjFTTyQlQCN0PWcu&embed=true"
        width="100%"
        style={{
          border: 'none',
          borderRadius: mobile ? '4px' : '20px',
          boxShadow: mobile ? '' : '0px 0px 15px 0px rgba(0,0,0,0.25)',
          zIndex: 1,
          height: '160vh',
          maxWidth: '800px',
          overflow: 'hidden',
        }}
        allowFullScreen
      />
    </Stack>
  )
}
