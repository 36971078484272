import { Box, Stack } from '@mui/material'
import { HashLink as Link } from 'react-router-hash-link'
import { PowerBITipsService } from './carlo-services/powerBI-tips'
import { IntelexosService } from './carlo-services/Intelexos'
import { PowerBITipsPlusService } from './carlo-services/tips-plus'

import { CarloSolutionsServiceType } from '../types'

import { CarloSolutionsService } from './carlo-services/carlo-solutions'

import { Features, Hero } from '../features/site/components'

import implementors from '../assets/implementors.webp'
import faster from '../assets/faster.webp'
import { CarloTrainingService } from './carlo-services/carlo-training'
import { Page } from '../features/site/components/Page'

export function MainPage() {
  return (
    <Page>
      <Content />
    </Page>
  )
}

function LandingPageFeatures() {
  const landingPageFeatures = [
    {
      title: 'We are the implementors',
      description:
        'Traditionally known as consulting, we like to focus on the delivery. We implement and accelerate your efforts to work with data. We have full stack app developers and full stack data developers. From back end systems to compelling reports we do it all',
      image: implementors,
    },
    {
      title: 'Get there faster',
      description:
        'Sometimes you just need someone who has already been there. Our accelerators takes lessons learned across numerous projects and distills down solutions that are quick to implement and just work. Accelerators are an excellent opportunity for your team to see real architectures working. We build it, and you run it. We enable companies with the following accelerators, Lakehouse, Power BI Embedded, Power BI Center of Excellence, and Power BI admin monitoring.',
      image: faster,
    },
  ]

  return <Features features={landingPageFeatures} />
}

const services: CarloSolutionsServiceType[] = [
  {
    section: '#powerbi-tips',
    component: PowerBITipsService,
  },
  {
    section: '#powerbi-tips-plus',
    component: PowerBITipsPlusService,
  },
  {
    section: '#carlo-solutions',
    component: CarloSolutionsService,
  },
  {
    section: '#carlo-training',
    component: CarloTrainingService,
  },
  {
    section: '#intelexos',
    component: IntelexosService,
  },
]

// box on initial page that will will link to more information about that service lower on the page
function Content() {
  // console.log('Services: ', services)

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          // px: (theme) => device === BreakpointDevice.Mobile ? theme.spacing(2):theme.spacing(10),
        }}
      >
        <Hero />
      </Box>

      <LandingPageFeatures />
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {services.map((service, index) => (
          <Stack key={index} sx={{ mt: 3, width: '100%' }}>
            <Link to={service.section}></Link>
            <service.component />
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
