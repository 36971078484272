import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Box, Stack } from '@mui/material'
import {
  BreakpointDevice,
  useBreakpoint,
} from '../../../../theme/useBreakpoint'
import { Feature } from '../../../../types'
import { Features } from '../Features'
import { HeroText, BlurbText } from '../typography'
// import { StyledServiceBox } from './Service.styles'

export type ServiceProps = {
  title?: string
  description?: string
  logo?: ReactJSXElement
  children?: any
  features?: Feature[]
  backgroundColor?: string
  backgroundImage?: string
  contrast?: boolean
}

export function Service(props: ServiceProps) {
  const {
    title,
    description,
    logo,
    children,
    features,
    backgroundColor,
    backgroundImage,
    contrast,
  } = props
  const { device } = useBreakpoint()

  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        mt: 4,
        p: 4,
        backgroundColor: backgroundColor || 'auto',
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: device === BreakpointDevice.Mobile ? 0 : 6,
        mx: device === BreakpointDevice.Mobile ? -2 : 0,
      }}
    >
      <Box
        sx={{
          width: '100%',
          mt: 5,
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {logo ? (
          <Box
            sx={{
              maxWidth: device === BreakpointDevice.Mobile ? '300px' : '400px',
              margin: '0 auto',
            }}
          >
            {logo}
          </Box>
        ) : (
          <HeroText
            sx={{
              color: contrast ? 'white' : 'auto',
            }}
          >
            {title}
          </HeroText>
        )}
      </Box>

      {description && (
        <BlurbText
          sx={{
            color: contrast ? 'white' : 'auto',
            maxWidth: '80%',
          }}
          align="center"
        >
          {description}
        </BlurbText>
      )}

      <Box
        sx={{
          margin: '16px',
          width: '100%',
        }}
      >
        {features && <Features features={features} />}

        {children}
      </Box>
    </Stack>
  )
}

// type ServiceLogoProps = {
//   logo: ReactJSXElement
//   device: BreakpointDevice
// }

// function ServiceLogo(props: ServiceLogoProps) {
//   const { logo, device } = props

//   return (
//     <Box
//       sx={{
//         maxHeight: '140px',
//         maxWidth: device === BreakpointDevice.Mobile ? '280px' : '400px',
//       }}
//     >
//       {logo}
//     </Box>
//   )
// }
