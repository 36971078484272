import { Box, Stack, Theme } from '@mui/material'

import { HeroText } from './typography'
import blueishBanner from '../../../assets/hero-background.webp'

import { ReactComponent as Letter } from '../../../assets/letter.svg'

export function ContactHero() {
  const bgImageStyle = {
    backgroundImage: `url(${blueishBanner})`,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    minHeight: '600px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: 6,
    p: 8,
    mb: 2,
  }

  return (
    <Box sx={bgImageStyle}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={6}
        sx={{ maxWidth: (theme) => theme.spacing(89), w: '100%' }}
      >
        <HeroText
          sx={{
            color: (theme: Theme) => theme.palette.primary.contrastText,
          }}
        >
          Contact
        </HeroText>

        <Letter
          style={{
            fill: 'white',
            width: '140px',
            marginBottom: '20px',
          }}
        />
      </Stack>
    </Box>
  )
}
