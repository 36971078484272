import { Box, Stack, SvgIcon } from '@mui/material'
import { HighlightedFeature } from '../../../types'
import { useState } from 'react'
import { FeatureText } from './typography/FeatureText'
import { BlurbText } from './typography'
import { BreakpointDevice, useBreakpoint } from '../../../theme/useBreakpoint'

type HighlightedFeaturesProps = {
  features: HighlightedFeature[]
  heading?: string
  buttonColor?: string
}

export function HighlightedFeatures(props: HighlightedFeaturesProps) {
  const { features, heading } = props

  const { device } = useBreakpoint()

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', maxWidth: '85%', margin: '0 auto' }}
    >
      {heading && (
        <BlurbText sx={{ color: 'white', mb: 6 }}>{heading}</BlurbText>
      )}
      <Stack
        direction={device === BreakpointDevice.Mobile ? 'column' : 'row'}
        alignItems={
          device === BreakpointDevice.Mobile ? 'center' : 'flex-start'
        }
        justifyContent="space-between"
        sx={{
          width: '100%',
        }}
        gap={8}
      >
        {features.map((feature, index) => (
          <HighlightedFeatureBox
            key={feature.title+index}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
            link={feature.link}
            device={device}
          />
        ))}
      </Stack>
    </Stack>
  )
}

type HighlightedFeatureBoxProps = {
  title: string
  description: string
  icon: any
  device: BreakpointDevice
  link?: string
}

function HighlightedFeatureBox(props: HighlightedFeatureBoxProps) {
  const { title, description, icon, link, device } = props
  const [hover, setHover] = useState(false)
  const featureLink = link || './contact'

  return (
    <Stack direction="column" alignItems="center" gap={2}>
      <Box
        onMouseOver={() => {
          setHover(true)
        }}
        onMouseOut={() => {
          setHover(false)
        }}
        onClick={() => {
          window.open(featureLink, '_blank')
        }}
        sx={{
          display: 'flex',

          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        <SvgIcon
          sx={{
            color: hover ? 'primary.main' : 'primary.light',
            fontSize: device === BreakpointDevice.Mobile ? '48px' : '64px',
          }}
        >
          {icon}
        </SvgIcon>
        <FeatureText
          sx={{
            color: hover ? 'primary.main' : 'primary.light',
            whiteSpace: 'nowrap',
          }}
        >
          {title}
        </FeatureText>
      </Box>
      <BlurbText
        sx={{ width: '100%', maxWidth: '300px', color: 'white' }}
        align="center"
      >
        {description}
      </BlurbText>
    </Stack>
  )
}
