import { ReactNode } from 'react'
import { Button } from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import { BreakpointDevice, useBreakpoint } from '../../../theme/useBreakpoint'
import WestIcon from '@mui/icons-material/West'

export function ActionButton(
  props: {
    sx?: any
    gradient?: boolean
    children?: ReactNode
    link?: string
    externalLink?: boolean
    backArrow?: boolean
  } = {}
) {
  const { sx, gradient, children, externalLink, backArrow } = props
  const { device } = useBreakpoint()

  return (
    <Button
      variant="contained"
      sx={{
        ...sx,
        borderRadius: '28px',
        height: device === BreakpointDevice.Mobile ? '40px' : '48px',
        background: (theme) =>
          gradient || externalLink
            ? `linear-gradient(to right,  ${theme.palette.primary.light}, ${theme.palette.primary.main}, ${theme.palette.primary.dark});`
            : theme.palette.primary.dark,
      }}
      endIcon={!backArrow && <EastIcon />}
      startIcon={backArrow && <WestIcon />}
      href={props.link || './contact'}
      target={externalLink ? '_blank' : ''}
      disableElevation
    >
      {children}
    </Button>
  )
}
