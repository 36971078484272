import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useGoogleAnalytics } from './useGoogleAnalytics'
import { AppRoute, pages } from '../../pages'

export const usePageViewTracking = (): void => {
  const googleAnalytics = useGoogleAnalytics()
  const location = useLocation()

  useEffect(() => {
    if (googleAnalytics) {
      const appRoute = Object.values(pages).find(
        (appRoute: AppRoute ) => appRoute.path === location.pathname
      )
      if (appRoute) {
        googleAnalytics.pageView({
          page: appRoute.path,
          title: appRoute.title,
        })
      }
    }
  }, [googleAnalytics, location.pathname])
}
